import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Navbar from './shared/navbar/Navbar';
import ContactFooter from './shared/footer/contactFooter/ContactFooter';
import "./layout.scss"
import MobileNavbar from "./shared/mobile-navbar/MobileNavbar";

const Layout = ({ children, home, hideFooter }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <section className="layout">
      <Navbar />
      <MobileNavbar />
      <main>{children}</main>
      {!hideFooter && (
        <ContactFooter />
      )}
    </section>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
