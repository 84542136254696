import React from 'react';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import './ContactFooter.scss';

const ContactFooter = () => {
  return (
    <footer>
      <div className="container">
        <div className="contact-footer">
          <div className="contact-footer__phone">
            © 2020 Marbel Plásticos
            <span> Tel.: (19) 3856-9191, 0800 776-3071</span>
          </div>
          <div className="contact-footer__social">
            <a
              href="https://www.facebok.com/Marbel.Plasticos"
              target="_blank"
            >
              <FaFacebookSquare size={24} />
            </a>
            <a
              href="https://www.instagram.com/marbelplasticos"
              target="_blank"
            >
              <FaInstagram size={24} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default ContactFooter;