import React from 'react';
import { Link } from 'gatsby';
import routes from '../../../utils/routes';
import MarbelIcon from '../icons/MarbelIcon';
import './Navbar.scss';

const Navbar = () => {
  return (
    <>
      <section className="navbar mobile-hidden">
        <div className="container container__min">
          <div className="navbar__wrapper">
            <div className="navbar-left">
              <Link
                to={routes.HOME}>
                <MarbelIcon style={{ width: '150px' }} />
              </Link>
            </div>
            <div className="navbar-right">
              <div className="navbar-link">
                <Link
                  className="navbar-link__clickable"
                  to={routes.HOME}
                  activeClassName="navbar-link--selected"
                >
                  Home
              </Link>
              </div>
              <div className="navbar-link">
                <Link
                  className="navbar-link__clickable"
                  to={routes.INSTITUTIONAL}
                  activeClassName="navbar-link--selected"
                >
                  Institucional
              </Link>
              </div>
              <div className="navbar-link">
                <Link
                  className="navbar-link__clickable"
                  to={routes.PRODUCTS}
                  activeClassName="navbar-link--selected"
                >
                  Produtos
              </Link>
              </div>
              <div className="navbar-link">
                <Link
                  className="navbar-link__clickable"
                  to={routes.CONTACT}
                  activeClassName="navbar-link--selected"
                >
                  Contato
              </Link>
              </div>
            </div>
            <div className="navbar-action">
              <a
                style={{ textDecoration: 'none' }}
                href="https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/catalogo/Cat%C3%A1logo_Marbel_2020.pdf"
                download="CatálogoMarbel2020.pdf"
                rel="noopener"
                target="_blank"
              >
                <button className="navbar-action__button">Baixar catálogo</button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Navbar;
