import React, { useState } from 'react';
import { Link } from 'gatsby';
import routes from '../../../utils/routes';
import MarbelIcon from '../icons/MarbelIcon';
import './MobileNavbar.scss';

const MobileNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  }

  return (
    <section className="mobile-navbar mobile">
      <div className="mobile-navbar--left">
        <Link
          to={routes.HOME}>
          <MarbelIcon style={{ width: '120px' }} />
        </Link>
      </div>
      <div className="mobile-navbar--right">
        <button
          type="button"
          className="mobile-navbar__toggle"
          onClick={toggleNavbar}>
          <div className={`mobile-navbar__hamburguer ${isOpen ? 'mobile-navbar__hamburguer--open' : ''}`} />
        </button>
      </div>
      <div className={`mobile-navbar__navigation ${isOpen ? 'mobile-navbar__navigation--open' : ''}`}>
        <Link
          className="mobile-navbar__link"
          to={routes.HOME}
          activeClassName="mobile-navbar__link mobile-navbar__link--selected"
        >
          Home
          </Link>
        <Link
          className="mobile-navbar__link"
          to={routes.INSTITUTIONAL}
          activeClassName="mobile-navbar__link mobile-navbar__link--selected"
        >
          Institucional
          </Link>
        <Link
          className="mobile-navbar__link"
          to={routes.PRODUCTS}
          activeClassName="mobile-navbar__link mobile-navbar__link--selected"
        >
          Produtos
          </Link>
        <Link
          className="mobile-navbar__link"
          to={routes.CONTACT}
          activeClassName="mobile-navbar__link mobile-navbar__link--selected"
        >
          Contato
          </Link>
        <div className="mobile-navbar__action">
          <a
            style={{ textDecoration: 'none' }}
            href="https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/catalogo/Cat%C3%A1logo_Marbel_2020.pdf"
            download="CatálogoMarbel2020.pdf"
            rel="noopener"
            target="_blank"
          >
            Baixar catálogo
          </a>
        </div>
      </div>
    </section>
  )
}

export default MobileNavbar;
